.base {
  background-color: var(--purple2);
  color: var(--white);
  padding: 20px;
  text-align: center;
  font-size: 17px;

  @media (--gt-largephone) {
    padding: 30px;
    font-size: 18px;
  }

  > div {
    @mixin container;

    @media (--gt-largephone) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  a {
    @mixin button-transparent;
    font-size: 14px;
    margin-top: 10px;

    @media (--gt-largephone) {
      margin-top: 0;
      margin-left: 30px;
    }
  }
}
